<template>
 <sdModal :onCancel="handleCancel" :visible="visible" title="" type="primary">
  <ModalMain>
   <div class="create-account-form">
    <a-row :gutter="30">
     <a-col :xs="24">
      <div
       id="adobe-dc-view"
       style="width: 100%; box-shadow: 1px 1px 10px 1px #dadada;display: flex;height:90vh;flex-flow: column;"
      />
     </a-col>
    </a-row>
   </div>
  </ModalMain>
 </sdModal>
</template>

<script>
import {defineComponent, ref} from 'vue';
import {getItem} from "@/utility/localStorageControl";

export default defineComponent({
 name: 'PdfViewer',
 setup() {
  let visible = ref(false);

  const handleCancel = () => {
   visible.value = false;
  }

  const loadAdobe = (pdf, authorization) => {
   // const themeConfig = JSON.parse(localStorage.getItem('themeConfig'));

   const viewerConfig = {
    embedMode: 'SIZED_CONTAINER',
    showPageControls: true,
   }

   // eslint-disable-next-line no-undef
   const adobeDCView = new AdobeDC.View({
    clientId: '6ab6a54f8fee419c90824e2671d67a5e',
    divId: 'adobe-dc-view',
   })

   adobeDCView.previewFile({
    content: {
     location: {
      url: pdf.fileURL,
      headers: authorization ? [{
       key: 'Authorization',
       value: `Bearer ${getItem('access_token')}`,
      }] : []
     },
    },
    metaData: {
     /* file name */
     fileName: pdf.fileName,
    },
   }, viewerConfig)
  }

  const showPdf = (pdf, authorization) => {
   visible.value = true;
   setTimeout(function () {
    loadAdobe(pdf, authorization)
   }, 1000);
  }

  return {
   handleCancel,
   showPdf,
   visible,
  };
 },
});
</script>
